<template id="optasetup">
  <b-container fluid>
    <h3>Micro and Weight Skill Player Setup</h3>
    <b-row>
      <b-col>
        <b-row>
          <b-col> ID - SKILLS </b-col>
          <b-col v-for="(group, key) in groups" :key="key" class="text-center">
            {{ group }}
            <b-row>
              <b-col cols="4"> micro </b-col>
              <b-col> weight </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div style="max-height: 73vh; overflow-y: scroll">
          <div v-for="skill in skills" :key="skill.id">
            <b-card no-body class="mb-1">
              <b-row align-v="center">
                <b-col>
                  {{ skill.id }} - {{ skill.label }} -
                  <span style="color: green; font-weight: bold">{{
                    skill.name
                  }}</span>
                </b-col>
                <b-col
                  v-for="(group, key) in groups"
                  :key="key"
                  class="text-center"
                >
                  <b-row align-v="center">
                    <b-col cols="4">
                      <b-form-checkbox
                        v-model="skills_micro[skill.id][key].micro"
                        :value="1"
                        :unchecked-value="0"
                      >
                      </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <b-form-input
                        type="number"
                        min="0"
                        max="1"
                        step="0.01"
                        v-model="skills_micro[skill.id][key].peso"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
        <b-button size="sm" variant="primary" class="mt-2" @click="updateSkills"
          >Save</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
export default {
  data: function () {
    return {
      skills: [],
      groups: {
        0: "PORTIERI",
        1: "DIFENSORI CENTRALI",
        2: "ESTERNI",
        3: "CENTROCAMPISTI CENTRALI",
        4: "INTERNI DI CENTROCAMPO",
        5: "ATTACCANTI CENTRALI",
        6: "ATTACCANTI ESTERNI",
      },
      skills_micro: {},
    };
  },

  components: {},

  created: function () {
    this.getSkills();
  },

  methods: {
    getSkills() {
      this.skills_micro = {};
      this.$http.get("/opta/skills/micro").then((response) => {
        this.skills = response.data;
        for (var i in this.skills) {
          var micro = this.skills[i].micro;
          var groups = {};
          for (var g in this.groups) {
            var obj = {
              peso: 1,
              micro: 0,
            };
            var index = micro.findIndex((item) => item.gruppo == g);
            if (index > -1) {
              var item = micro[index];
              obj.peso = item.peso;
              obj.micro = item.micro;
            }
            groups[g] = obj;
          }
          this.skills_micro[this.skills[i].id] = groups;
        }
      });
    },

    updateSkills() {
      this.$http
        .post("/opta/update/micro/player", this.skills_micro)
        .then((response) => {
          this.getSkills();
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
        });
    },
  },

  filters: {},
};
</script>
